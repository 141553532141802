/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import audiencesSlice from "store/slices/audiencesSlice";
import authSlice from "store/slices/authSlice";
import collectionSlice from "store/slices/collectionSlice";
import generalSlice from "store/slices/generalSlice";
import surveySlice from "store/slices/surveySlice";

const store = configureStore({
  reducer: {
    auths: authSlice,
    generals: generalSlice,
    surveys: surveySlice,
    collections: collectionSlice,
    audiences: audiencesSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
