import { CheckCircle, Error, Home, Instagram, Settings, ThumbUp } from "@mui/icons-material";
import { ReactComponent as Analytics } from "assets/images/AnalyticsIcon.svg";
import { ReactComponent as ClockLoader } from "assets/images/ClockLoader.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as Decreasing } from "assets/images/Decreasing.svg";
import { ReactComponent as DotIcon } from "assets/images/DotIcon.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/FacebookIcon.svg";
import { ReactComponent as FilterIcon } from "assets/images/FilterIcon.svg";
import { ReactComponent as GithubIcon } from "assets/images/GithubIcon.svg";
import { ReactComponent as GoBackIcon } from "assets/images/GoBackIcon.svg";
import { ReactComponent as GoogleIcon } from "assets/images/GoogleIcon.svg";
import { ReactComponent as ImpExpIcon } from "assets/images/ImpExpIcon.svg";
import { ReactComponent as Increasing } from "assets/images/Increasing.svg";
import { ReactComponent as LinkedInIcon } from "assets/images/LinkedInIcon.svg";
import { ReactComponent as LinkIcon } from "assets/images/LinkIcon.svg";
import { ReactComponent as MicrosoftIcon } from "assets/images/MicrosoftIcon.svg";
import { ReactComponent as Resources } from "assets/images/ResourcesIcon.svg";
import { ReactComponent as Respondents } from "assets/images/RespondentsIcon.svg";
import { ReactComponent as SearchIcon } from "assets/images/SearchIcon.svg";
import { ReactComponent as StoryIcon } from "assets/images/StoryIcon.svg";
import { ReactComponent as Surveys } from "assets/images/SurveysIcon.svg";
import { ReactComponent as ThemeIcon } from "assets/images/ThemeIcon.svg";
import { ReactComponent as TwitterIcon } from "assets/images/TwitterIcon.svg";
import { ReactComponent as UnderlineIcon } from "assets/images/UnderlineIcon.svg";

export const getIcon = (provider) => {
  switch (provider.toLowerCase()) {
    case "facebook":
      return <FacebookIcon />;
    case "google":
      return <GoogleIcon />;
    case "github":
      return <GithubIcon />;
    case "linkedin":
      return <LinkedInIcon sx={{ color: "inherit" }} />;
    case "twitter":
      return <TwitterIcon />;
    case "instagram":
      return <Instagram sx={{ color: "inherit" }} />;
    case "outlook":
      return <MicrosoftIcon />;
    case "thumbsup":
      return <ThumbUp sx={{ color: "inherit" }} />;
    case "error":
      return <Error sx={{ color: "inherit" }} />;
    case "success":
      return <CheckCircle sx={{ color: "inherit" }} />;
    case "search":
      return <SearchIcon />;
    case "surveys":
      return <Surveys />;
    case "analytics":
      return <Analytics />;
    case "respondents":
      return <Respondents />;
    case "resources":
      return <Resources />;
    case "filter":
      return <FilterIcon />;
    case "impexp":
      return <ImpExpIcon />;
    case "close":
      return <CloseIcon />;
    case "goback":
      return <GoBackIcon />;
    case "clockloader":
      return <ClockLoader />;
    case "settings":
      return <Settings />;

    case "underline":
      return <UnderlineIcon />;
    case "link":
      return <LinkIcon />;
    case "edit":
      return <EditIcon />;
    case "home":
      return <Home />;
    case "decreasing":
      return <Decreasing />;
    case "increasing":
      return <Increasing />;
    case "story":
      return <StoryIcon />;
    case "theme":
      return <ThemeIcon />;
    case "dot":
      return <DotIcon />;

    default:
      return null;
  }
};

export default getIcon;
