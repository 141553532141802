/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import { STATUSES } from "data/constants/STATUSES";

const renameAttribute = (data, type) =>
  data.map((item) => {
    const updatedObject = {
      ...item,
      metadata: item[`${type}_metadata`].map((metadata) => ({
        content_type_id: metadata.content_type.id,
        id: metadata.id,
        locale_id: metadata.locale.id,
        sequence: metadata.sequence,
        value: metadata.value,
      })),
    };

    delete updatedObject[`${type}_metadata`];
    return updatedObject;
  });

const uri = "collections";

export const getQuestions = createAsyncThunk(
  `${uri}/getQuestions`,
  async (_requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    const { selectedLocale } = getState().generals;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `questions?locale_id=${selectedLocale.id}&template=1`,
        "GET"
      );

      if (response.code === "success") {
        response.data.questions = renameAttribute(response.data.questions, "question");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const updateQuestion = createAsyncThunk(
  `${uri}/updateQuestion`,
  async ({ questions, reference }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`questions/${reference}`, "PUT", { questions });

      if (response.code === "success") {
        response.data.questions = renameAttribute(response.data.questions, "question");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const postQuestion = createAsyncThunk(
  `${uri}/postQuestion`,
  async (questionData, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`questions`, "POST", questionData);

      if (response.code === "success") {
        response.data.questions = renameAttribute(response.data.questions, "question");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const deleteQuestions = createAsyncThunk(
  `${uri}/deleteQuestions`,
  async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    let response;
    try {
      for (let i = 0; i < ids.length; i += 1) {
        response = await requestApi(`questions/${ids[i]}`, "PUT", { active: 0 });
      }
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const getOptions = createAsyncThunk(
  `${uri}/getOptions`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      const response = await requestApi(`options?template=1`, "GET");

      if (response.code === "success") {
        response.data.options = renameAttribute(response.data.options, "option");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const putOption = createAsyncThunk(
  `${uri}/putOption`,
  async ({ options, reference }, { getState, requestId, rejectWithValue, dispatch }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`options/${reference}`, "PUT", { options });

      if (response.code === "success") {
        response.data.options = renameAttribute(response.data.options, "option");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const deleteOptions = createAsyncThunk(
  `${uri}/deleteOptions`,
  async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    let response;
    try {
      for (let i = 0; i < ids.length; i += 1) {
        response = await requestApi(`options/${ids[i]}`, "PUT", { active: 0 });
      }
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const postOption = createAsyncThunk(
  `${uri}/postOption`,
  async (optionData, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`options`, "POST", optionData);
      if (response.code === "success") {
        response.data.options = renameAttribute(response.data.options, "option");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const getReactions = createAsyncThunk(
  `${uri}/getReactions`,
  async ({ optionId }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`reactions?template=1`, "GET");

      if (response.code === "success") {
        response.data.reactions = renameAttribute(response.data.reactions, "reaction");

        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const putReaction = createAsyncThunk(
  `${uri}/putReaction`,
  async ({ reactions, reference }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`reactions/${reference}`, "PUT", { reactions });

      if (response.code === "success") {
        response.data.reactions = renameAttribute(response.data.reactions, "reaction");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const deleteReactions = createAsyncThunk(
  `${uri}/deleteReactions`,
  async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    let response;
    try {
      for (let i = 0; i < ids.length; i += 1) {
        response = await requestApi(`reactions/${ids[i]}`, "PUT", { active: 0 });
      }
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const postReaction = createAsyncThunk(
  `${uri}/postReaction`,
  async (reactionData, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`reactions`, "POST", reactionData);
      if (response.code === "success") {
        response.data.reactions = renameAttribute(response.data.reactions, "reaction");
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const getLaunchTemplates = createAsyncThunk(
  `${uri}/getLaunchTemplates`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`templates?type=launch`, "GET");
      if (response.code === "success") {
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const getTemplatePreview = createAsyncThunk(
  `${uri}/getTemplatePreview`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `templates/${requestBody.templateId}?preview=1&type=${requestBody.type}&survey_id=${requestBody.surveyId}
`,
        "GET"
      );
      if (response.code === "success") {
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const getReminderTemplates = createAsyncThunk(
  `${uri}/getReminderTemplates`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`templates?type=reminder`, "GET");
      if (response.code === "success") {
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const getThemes = createAsyncThunk(
  `${uri}/getThemes`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`themes?interface_id=${requestBody.interfaceId}`, "GET");
      if (response.code === "success") {
        return response.data;
      }

      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
