import "./style.css";

import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box } from "components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import uploadImageAndGetURL from "services/exports/uploadImageAndGetURL";

function RichTextEditor({ content, handleContentChange, showError, filePath, readOnly }) {
  const [contentState, setEditorState] = useState(content);
  const [editorBorderColor, setEditorBorderColor] = useState(colors.inputBorderColor);

  useEffect(() => {
    if (showError)
      if (!content) {
        setEditorBorderColor(colors.error.main);
      } else {
        setEditorBorderColor(colors.secondary.main);
      }
  }, [contentState, showError]);

  const onContentStateChange = (state) => {
    setEditorState(state);
  };

  const pushStateToParent = useCallback(() => {
    if (
      contentState === null ||
      contentState.blocks.length < 1 ||
      contentState.entityMap.length < 1 ||
      contentState.blocks[0].text === ""
    ) {
      handleContentChange(null);
    } else handleContentChange(contentState);
  }, [contentState]);

  return (
    <Box
      color="text"
      sx={({ typography: { size } }) => ({
        fontSize: size.sm,
      })}
    >
      <Editor
        readOnly={readOnly}
        onBlur={() => {
          setEditorBorderColor(colors.inputBorderColor);
          pushStateToParent();
        }}
        onFocus={() => {
          setEditorBorderColor(colors.secondary.main);
        }}
        initialContentState={contentState}
        onContentStateChange={onContentStateChange}
        editorStyle={{
          borderRadius: "5px",
          background: "white",
          lineHeight: "unset",
          border: "1px solid",
          borderColor: editorBorderColor,
          minHeight: pxToRem(200),
          width: "100%",
          padding: pxToRem(5),
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "first name", value: "first_name", url: "" },
            { text: "last name", value: "last_name", url: "" },
            { text: "survey link", value: "survey_link", url: "" },
          ],
        }}
        toolbar={{
          options: ["inline", "link"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          image: {
            // icon: image,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            previewImage: true,
            alignmentEnabled: true,
            uploadCallback: async (data) => ({
              data: { link: await uploadImageAndGetURL(data, filePath) },
            }),
            inputAccept: "image/jpeg,image/jpg,image/png",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
            title: undefined,
          },
        }}
      />
    </Box>
  );
}

RichTextEditor.defaultProps = {
  content: null,
  showError: true,
  readOnly: false,
  filePath: null,
};

RichTextEditor.propTypes = {
  content: PropTypes.instanceOf(Object),
  handleContentChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  filePath: PropTypes.string,
};

export default RichTextEditor;
