// prop-types is a library for typechecking of props
// @mui material components
import Icon from "@mui/material/Icon";
//  components
import Box from "components/Box";
import Typography from "components/Typography";
import PropTypes from "prop-types";
// react-router-dom components
import { Link } from "react-router-dom";

function DefaultNavbarLink({ icon, name, route, light }) {
  return (
    <Box
      component={Link}
      to={route}
      mx={0}
      p={1}
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer", userSelect: "none" }}
    >
      <Icon
        sx={{
          color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
          verticalAlign: "middle",
        }}
      >
        {icon}
      </Icon>
      <Typography
        variant="h6"
        color={light ? "white" : "dark"}
        sx={{ width: "100%", lineHeight: 0 }}
      >
        &nbsp;{name}
      </Typography>
    </Box>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.defaultProps = {
  icon: null,
};
DefaultNavbarLink.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  light: PropTypes.bool.isRequired,
};

export default DefaultNavbarLink;
