import { Box } from "components";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";

function DraggableContainer({ id, type, children, item }) {
  const [{ isDragging }, drag] = useDrag({
    type,
    item: item || id,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={drag}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? "grabbing" : "grab",
      }}
    >
      {children}
    </Box>
  );
}

DraggableContainer.defaultProps = {
  item: null,
  id: null,
};

DraggableContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  item: PropTypes.any,
};

export default DraggableContainer;
