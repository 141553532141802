import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "data/constants/STATUSES";
import convertQuestionsIntoNestedData from "services/utils/convertQuestionsIntoNestedData";
import {
  createSurvey,
  deleteSurvey,
  getDeletedSurveys,
  getSurvey,
  getSurveys,
  getTemplates,
  updateSurvey,
} from "store/actions/surveys";

function setLoadingState(state, action) {
  if (state.status === STATUSES.IDLE) {
    state.status = STATUSES.LOADING;
    state.currentRequestId = action.meta.requestId;
  }
}

function setErrorState(state, action) {
  const { requestId } = action.meta;
  if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
    state.errorMessage = action.payload ? action.payload : action.error.message;
    state.currentRequestId = undefined;
    state.status = STATUSES.ERROR;
  }
}

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  survey: null,
  questionnaire: [],
  templates: [],
  surveys: [],
  deletedSurveys: [],
  showDraftError: false,
  draftErrors: [],
};

export const surveySlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    setSurvey(state, action) {
      state.survey = action.payload;
    },
    setShowDraftError(state, action) {
      state.showDraftError = action.payload;
    },
    setDraftErrors(state, action) {
      state.draftErrors = action.payload;
    },
    clearSurveysErrorState(state) {
      state.status = STATUSES.IDLE;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, setLoadingState)
      .addCase(getTemplates.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { surveys } = action.payload;
          const templates = Object.values(
            surveys.reduce((acc, item) => {
              const { id } = item.category;
              acc[id] = acc[id] || { templates: [], category: item.category.category, id };
              acc[id].templates.push(item);
              return acc;
            }, {})
          );
          state.status = STATUSES.IDLE;
          state.templates = templates;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getTemplates.rejected, setErrorState)

      .addCase(getSurveys.pending, setLoadingState)
      .addCase(getSurveys.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { surveys } = action.payload;
          const [activeSurveys, inActiveSurveys] = surveys;
          state.status = STATUSES.IDLE;
          state.surveys = activeSurveys;
          state.deletedSurveys = inActiveSurveys;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getSurveys.rejected, setErrorState)
      .addCase(getDeletedSurveys.pending, setLoadingState)
      .addCase(getDeletedSurveys.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { surveys } = action.payload;
          state.status = STATUSES.IDLE;
          state.deletedSurveys = surveys;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getDeletedSurveys.rejected, setErrorState)

      .addCase(getSurvey.pending, setLoadingState)
      .addCase(getSurvey.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { survey } = action.payload;

          state.survey = {
            ...survey,
            survey_draft: {
              ...survey.survey_draft,
              draft_data: convertQuestionsIntoNestedData(survey.survey_draft.draft_data),
            },
          };

          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(getSurvey.rejected, setErrorState)

      .addCase(createSurvey.pending, setLoadingState)
      .addCase(createSurvey.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { survey } = action.payload;
          state.status = STATUSES.IDLE;
          state.surveys.unshift(survey);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createSurvey.rejected, setErrorState)

      .addCase(updateSurvey.pending, setLoadingState)
      .addCase(updateSurvey.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { survey } = action.payload;
          const index = state.surveys.findIndex((s) => s.id === survey.id);
          if (index !== -1) {
            state.surveys[index] = survey;
          }
          state.survey = {
            ...survey,
            survey_draft: {
              ...survey.survey_draft,
              draft_data: convertQuestionsIntoNestedData(survey.survey_draft.draft_data),
            },
          };
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateSurvey.rejected, setErrorState)

      .addCase(deleteSurvey.pending, setLoadingState)
      .addCase(deleteSurvey.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { survey } = action.payload;
          state.surveys = [...state.surveys.filter((item) => item.id !== survey.id)];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteSurvey.rejected, setErrorState);
  },
});

// Action creators are generated for each case reducer function
export const { clearSurveysErrorState, setSurvey, setShowDraftError, setDraftErrors } =
  surveySlice.actions;

export default surveySlice.reducer;
