/* eslint-disable camelcase */
import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "data/constants/STATUSES";
import cookies from "services/browserStorage/cookies";
import { readFromLocalStorage, writeToLocalStorage } from "services/browserStorage/localStorage";
import {
  createTeam,
  deleteTeamInvite,
  deleteTeamUser,
  getAuthSession,
  getProviders,
  getTeams,
  getUserProfile,
  inviteTeamUser,
  logout,
  requestAuth,
  requestSocialAuth,
  updateUserProfile,
  verifyLogin,
  verifyTeamInvite,
} from "store/actions/auths";

const initialState = {
  currentRequestId: undefined,
  sessionState: readFromLocalStorage("sessionState"),
  status: STATUSES.IDLE,
  requestState: null,
  errorMessage: null,
  providers: [],
  selectedProvider: readFromLocalStorage("selectedProvider"),
  userData: cookies.getAuth().user,
  session: { token: null, expiry: null },
  teams: [],
  team: null,
};

export const authSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    setSessionState(state, action) {
      writeToLocalStorage("sessionState", action.payload);
      state.sessionState = action.payload;
    },
    setSelectedProvider(state, action) {
      writeToLocalStorage("selectedProvider", action.payload);
      state.selectedProvider = action.payload;
    },
    clearAuthErrorState(state) {
      state.status = STATUSES.IDLE;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ################# Getting providers ####################
      .addCase(getProviders.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { providers } = action.payload;
          state.status = STATUSES.IDLE;
          state.providers = providers;
          state.selectedProvider = providers.some((provider) => provider.code === "credentials")
            ? providers[0].id
            : null;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getProviders.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Authentication request ####################
      .addCase(requestAuth.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(requestAuth.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { authentication_request } = action.payload;

          state.reference = authentication_request.reference;
          state.expiry = authentication_request.expiry;
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(requestAuth.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(requestSocialAuth.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(requestSocialAuth.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { authentication_request } = action.payload;

          state.reference = authentication_request.reference;
          state.expiry = authentication_request.expiry;
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(requestSocialAuth.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Auth verification ####################
      .addCase(verifyLogin.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(verifyLogin.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { session, user } = action.payload;

          state.status = STATUSES.IDLE;
          state.session.token = session.token;
          state.session.expiry = session.expiry;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(verifyLogin.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getAuthSession.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getAuthSession.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { session, user } = action.payload;

          state.status = STATUSES.IDLE;
          state.session.token = session.token;
          state.session.expiry = session.expiry;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getAuthSession.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Logging out ####################
      .addCase(logout.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(logout.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { user } = action.payload;

          state.status = STATUSES.IDLE;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getUserProfile.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { user } = action.payload;

          state.status = STATUSES.IDLE;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(createTeam.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { team } = action.payload;

          state.status = STATUSES.IDLE;
          state.team = team;
          state.teams = [...team, ...current(state.teams)];
          state.currentRequestId = undefined;
        }
      })
      .addCase(createTeam.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getTeams.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { teams } = action.payload;

          state.status = STATUSES.IDLE;
          state.teams = teams;
          // eslint-disable-next-line prefer-destructuring
          state.team = teams.filter((team) => team.current === 1)[0];
          state.currentRequestId = undefined;
        }
      })
      .addCase(getTeams.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(inviteTeamUser.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(inviteTeamUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(inviteTeamUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(deleteTeamUser.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteTeamUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { team_user } = action.payload;
          const index = current(state.teams).findIndex((a) => a.id === team_user.team_id);

          if (index !== -1) {
            const teams = [...current(state.teams)];

            const updatedTeam = {
              ...teams[index],
              team_users: teams[index].team_users.filter(
                (teamUser) => teamUser.id !== team_user.id
              ),
            };
            teams[index] = updatedTeam;
            state.teams = teams;
            state.team = updatedTeam;
          }
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteTeamUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })

      .addCase(deleteTeamInvite.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteTeamInvite.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { team_invite } = action.payload;
          const index = current(state.teams).findIndex((a) => a.id === team_invite.team_id);

          if (index !== -1) {
            const teams = [...current(state.teams)];

            const updatedTeam = {
              ...teams[index],
              team_users: teams[index].team_users.filter(
                (teamUser) => teamUser.id !== team_invite.id && teamUser.status === "pending"
              ),
            };
            teams[index] = updatedTeam;
            state.teams = teams;
            state.team = updatedTeam;
          }
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteTeamInvite.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(verifyTeamInvite.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(verifyTeamInvite.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { session, user } = action.payload;

          state.status = STATUSES.IDLE;
          state.session.token = session.token;
          state.session.expiry = session.expiry;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(verifyTeamInvite.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearReference, clearAuthErrorState, setSelectedProvider, setSessionState } =
  authSlice.actions;

export default authSlice.reducer;
