import { ANSWERS, QUESTION, QUESTION_N_OPTIONS } from "data/constants/SURVEY_DRAFT";
import uniqueId from "services/utils/generateUniqueId";
import sortByProperty from "services/utils/sortByProperty";

const convertQuestionsIntoNestedData = (draft) => {
  try {
    if (draft && !draft.questions) {
      return [{ ...QUESTION_N_OPTIONS, sequence: 1, uuid: uniqueId() }];
    }

    const questionsArr = [];
    const followUpArr = [];

    draft.questions.forEach((question) => {
      if (question.follow_up === 1) {
        followUpArr.push(question);
      } else {
        questionsArr.push(question);
      }
    });

    const nestedQuestions = questionsArr.map((question) => {
      const { options, ...rest } = question;
      return {
        question: rest,
        sequence: question.sequence,
        uuid: question.uuid,
        options: question.options.map((option) => ({
          ...option,
          question: followUpArr.find((followUp) => followUp.uuid === option.next_question_id),
        })),
      };
    });
    // The nestedQuestion object above is correct. However, I am mapping it again to ensure that any missing values will be prefilled by the boiler object. This prevents potential UI crashes due to null or missing values.

    const finalQuestions = nestedQuestions.map((question) => ({
      uuid: uniqueId(),
      sequence: 1,
      ...question,
      question: { ...QUESTION, ...question.question },
      options: question.options.map((option) => ({
        ...ANSWERS[0],
        ...option,
        question: { ...ANSWERS[0].question, ...option.question },
      })),
    }));
    return sortByProperty(finalQuestions, "sequence");
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle the error or return a meaningful value
    return []; // Return an empty array as an example
  }
};

export default convertQuestionsIntoNestedData;
