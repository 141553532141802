/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "data/constants/STATUSES";
import {
  createAudience,
  createAudienceUser,
  createUser,
  deleteAudience,
  deleteAudienceUser,
  getAudience,
  getAudiences,
  getAudienceUser,
  updateAudience,
  updateUser,
} from "store/actions/audiences";

function setLoadingState(state, action) {
  if (state.status === STATUSES.IDLE) {
    state.status = STATUSES.LOADING;
    state.currentRequestId = action.meta.requestId;
  }
}

function setErrorState(state, action) {
  const { requestId } = action.meta;
  if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
    state.errorMessage = action.payload ? action.payload : action.error.message;
    state.currentRequestId = undefined;
    state.status = STATUSES.ERROR;
  }
}

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  audiences: [],
  audience: null,
  currentAudienceUser: [],
  users: [],
};

export const audienceSlice = createSlice({
  name: "audiences",
  initialState,
  reducers: {
    clearAudiencesErrorState(state) {
      state.status = STATUSES.IDLE;
      state.errorMessage = null;
    },
    setAudience(state, action) {
      state.audience = action.payload;
      state.currentAudienceUser = action.payload.audience_users.map((user) => {
        const transformedObject = {
          ...user,
        };
        user.attributes.forEach((attr) => {
          transformedObject[attr.attribute] = attr.value;
        });
        return transformedObject;
      });
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAudiences.pending, setLoadingState)
      .addCase(getAudiences.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audiences } = action.payload;
          state.status = STATUSES.IDLE;
          state.audiences = audiences;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getAudiences.rejected, setErrorState)

      .addCase(getAudience.pending, setLoadingState)
      .addCase(getAudience.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audience } = action.payload;
          state.audience = audience;
          state.currentAudienceUser = audience.audience_users.map((user) => {
            const transformedObject = {
              ...user,
            };
            user.attributes.forEach((attr) => {
              transformedObject[attr.attribute] = attr.value;
            });
            return transformedObject;
          });
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(getAudience.rejected, setErrorState)

      .addCase(createAudience.pending, setLoadingState)
      .addCase(createAudience.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audience } = action.payload;
          state.status = STATUSES.IDLE;
          state.audiences.push(audience);
          state.audience = audience;
          state.currentRequestId = undefined;
        }
      })
      .addCase(createAudience.rejected, setErrorState)

      .addCase(updateAudience.pending, setLoadingState)
      .addCase(updateAudience.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audience } = action.payload;
          const index = state.audiences.findIndex((s) => s.id === audience.id);
          if (index !== -1) {
            state.audiences[index] = audience;
          }
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateAudience.rejected, setErrorState)

      .addCase(deleteAudience.pending, setLoadingState)
      .addCase(deleteAudience.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audience } = action.payload;
          state.audiences = [...state.audiences.filter((item) => item.id !== audience.id)];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteAudience.rejected, setErrorState)

      .addCase(createUser.pending, setLoadingState)
      .addCase(createUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // const { audience } = action.payload;
          // state.audiences = [...state.audiences.filter((item) => item.id !== audience.id)];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(createUser.rejected, setErrorState)
      .addCase(updateUser.pending, setLoadingState)
      .addCase(updateUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // const { audience } = action.payload;
          // const index = state.audiences.findIndex((s) => s.id === audience.id);
          // if (index !== -1) {
          //   state.templates[index] = audience;
          // }
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateUser.rejected, setErrorState)

      .addCase(createAudienceUser.pending, setLoadingState)
      .addCase(createAudienceUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // const { audience } = action.payload;
          // state.audiences = [...state.audiences.filter((item) => item.id !== audience.id)];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(createAudienceUser.rejected, setErrorState)

      .addCase(getAudienceUser.pending, setLoadingState)
      .addCase(getAudienceUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // state.audiences = [...state.audiences.filter((item) => item.id !== audience.id)];

          state.currentAudienceUser = action.payload.audience_users.map((user) => {
            const transformedObject = {
              ...user,
            };
            user.attributes.forEach((attr) => {
              transformedObject[attr.attribute] = attr.value;
            });
            return transformedObject;
          });
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getAudienceUser.rejected, setErrorState)

      .addCase(deleteAudienceUser.pending, setLoadingState)
      .addCase(deleteAudienceUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { audience_user } = action.payload;
          state.currentAudienceUser = [
            ...state.currentAudienceUser.filter((item) => item.id !== audience_user.id),
          ];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteAudienceUser.rejected, setErrorState);
  },
});

// Action creators are generated for each case reducer function
export const { clearAudiencesErrorState, setAudience } = audienceSlice.actions;

export default audienceSlice.reducer;
