import { Container } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Typography } from "components";
import { DefaultNavbar, PageLayout } from "modules";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Footer from "views/auths/components/Footer";
import { description, header, showMoreRead, subHeader } from "views/static/PrivacyPolicy/style";

function PrivacyPolicy() {
  const [showMore, setShowMore] = useState(false);
  const [showMoreHide, setShowMoreHide] = useState(false);

  const handleButtonClick = () => {
    setShowMore(!showMore);
    setShowMoreHide(true);
  };
  const { translations } = useSelector((state) => state.generals);
  const navMenuList = [translations.HOW_THIS_WORKS, translations.PRICING, translations.CONTACT].map(
    (nav) => ({
      name: nav,
      route: nav.split("_").join("-").toLowerCase(),
    })
  );
  return (
    <PageLayout>
      <DefaultNavbar transparent navMenu={navMenuList} />
      <Box
        width="100%"
        height="calc(100vh - 56px)"
        className="flex-display flex-direction-column justify-content-between"
      >
        <Container sx={{ height: "inherit", mt: pxToRem(10), overflow: "scroll" }}>
          <Box px={{ md: 15 }}>
            <Typography sx={(theme) => header(theme)}>Privacy Policy</Typography>
          </Box>
          <Box
            className=" flex-display flex-direction-column align-items-start"
            gap={pxToRem(16)}
            px={{ md: 15 }}
            mt={pxToRem(10)}
          >
            <Typography sx={(theme) => subHeader(theme)}>
              We care about data privacy and security. Please review our Privacy Policy:
            </Typography>
            <Typography sx={(theme) => description(theme)}>
              By using the Site, you agree to be bound by our Privacy Policy, which is incorporated
              into these Terms and Conditions. Please be advised the Site is hosted in India. If you
              access the Site from any other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ from applicable
              laws in India, then through your continued use of the Site, you are transferring your
              data to India, and you agree to have your data transferred to and processed in India.
              {showMore && (
                <>
                  Further, we do not knowingly accept, request, or solicit information from children
                  or knowingly market to children. Therefore, in accordance with the U.S. Children’s
                  Online Privacy Protection Act, if we receive actual knowledge that anyone under
                  the age of 13 has provided personal information to us without the requisite and
                  verifiable parental consent, we will delete that information from the Site as
                  quickly as is reasonably practical.
                </>
              )}
            </Typography>
            {!showMoreHide && (
              <Typography sx={(theme) => showMoreRead(theme)} onClick={handleButtonClick}>
                read more
              </Typography>
            )}
          </Box>
        </Container>
        {/* 
        <Footer light />
        */}
      </Box>
    </PageLayout>
  );
}
export default PrivacyPolicy;
