/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import {
  MaterialReactTable,
  // MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  useMaterialReactTable,
} from "material-react-table";
import { TableActions } from "modules";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

function DataTable({
  data,
  columns,
  onCellClick,
  onRowClick,
  onTableActionClick,
  onEditTable,
  canEdit,
  renderMinimalTable,
  disabledIDs,
}) {
  const tableInstance = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showGlobalFilter: !renderMinimalTable,
      density: "compact",
      columnPinning: { right: ["mrt-row-actions"] },
    },
    enableTopToolbar: !renderMinimalTable,
    enableSorting: !renderMinimalTable,
    enableColumnActions: false,
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      variant: "outlined",
      size: "small",
    },
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableEditing: canEdit,
    muiEditTextFieldProps: ({ cell }) => ({
      variant: "outlined",
      size: "small",
      error: !cell.getValue(),
      onBlur: (event) => {
        onEditTable(cell.column.id, cell.row.index, event.target.value);
      },
    }),
    editDisplayMode: "table",
    enableRowActions: !!onTableActionClick,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    enableBottomToolbar: data?.length > 8,
    muiTableBodyRowProps: () => ({
      hover: !canEdit,
    }),
    enableRowVirtualization: data?.length > 8,
    positionActionsColumn: "last",
    positionGlobalFilter: "left",
    renderRowActions: ({ row }) =>
      onTableActionClick && (
        <TableActions
          selectedRow={row.original}
          onTableActionClick={onTableActionClick}
          disabledIDs={disabledIDs}
        />
      ),
    enableColumnPinning: true,
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
    paginationDisplayMode: "pages",

    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      style: {
        zIndex: table.getState().isFullScreen ? 1500 : undefined,
      },
    }),
    muiTableHeadCellProps: {
      sx: ({ typography: { size, fontWeightBold }, palette }) => ({
        color: palette.text.main,
        opacity: 0.7,
        fontSize: size.xs,
        fontWeight: fontWeightBold,
        textTransform: "capitalize",
      }),
    },
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: () => {
        if (cell.column.id !== "mrt-row-actions" && onCellClick) {
          onCellClick(cell);
        }
      },
      sx: ({ typography: { size, fontWeightRegular }, palette }) => ({
        fontSize: size.sm,
        color: palette.text.main,
        fontWeight: fontWeightRegular,
        cursor: "pointer",
      }),
    }),
  });

  return <MaterialReactTable table={tableInstance} />;
}

DataTable.defaultProps = {
  columns: [],
  onRowClick: null,
  onCellClick: null,
  onTableActionClick: null,
  onEditTable: null,
  canEdit: false,
  renderMinimalTable: false,
  disabledIDs: [],
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onTableActionClick: PropTypes.func,
  onEditTable: PropTypes.func,
  canEdit: PropTypes.bool,
  renderMinimalTable: PropTypes.bool,
  disabledIDs: PropTypes.arrayOf(PropTypes.number),
};

export default DataTable;
