// prop-types is a library for typechecking of props
import { Grid, IconButton, InputAdornment, Link } from "@mui/material";
// @mui material components
import Container from "@mui/material/Container";
import pxToRem from "assets/theme/functions/pxToRem";
//  components
import { Box, Input, Typography } from "components";
import { getIcon } from "data/icons/icon";
import { SelectInput } from "modules";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocaleTranslations } from "store/actions/generals";
import Dispatcher from "store/Dispatcher";
import { setSelectedLocale } from "store/slices/generalSlice";

function Footer({ light }) {
  const { locales, selectedLocale, translations } = useSelector((state) => state.generals);
  const navigate = useNavigate();

  const navigateToPrivacyPolicy = () => {
    navigate("/static/privacy-policy");
  };
  const navigateToFrequently = () => {
    navigate("/static/faq");
  };
  const navigateToAboutUs = () => {
    navigate("/static/about-us");
  };
  const socialMediaHandlers = [
    { name: "facebook", link: "" },
    { name: "instagram", link: "" },
    { name: "twitter", link: "" },
  ];

  const updateLocale = async (languageCode) => {
    const userSelectedLocale = locales.filter((item) => item.language_code === languageCode)[0];
    await Dispatcher({ action: setSelectedLocale, payload: userSelectedLocale, isAsync: false });
    await Dispatcher({ action: getLocaleTranslations, payload: userSelectedLocale });
  };

  return (
    <Box position="relative" width="100%" bgColor="black" bottom={0} py={2}>
      <Container>
        <Box mt={0} mb={0} textAlign="left">
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 0, mb: 0 }}>
            <Grid item lg={2} xs={4}>
              <Box
                className="flex-container-column cursor-pointer align-items-start "
                gap={pxToRem(16)}
              >
                <Typography
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  component={Link}
                  // href="https://www.kaiform.ai/privacy.html#privacypolicy1"
                  onClick={navigateToPrivacyPolicy}
                >
                  {translations.PRIVACY_POLICY}
                </Typography>
                <Typography
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  component={Link}
                  href="https://www.kaiform.ai/tos.html"
                >
                  {translations.T_N_C}
                </Typography>
                <Typography variant="caption" color="white" fontWeight="bold">
                  Data Processing Agreement
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2} xs={4}>
              <Box
                className="flex-container-column cursor-pointer align-items-start"
                gap={pxToRem(16)}
              >
                <Typography
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  onClick={navigateToFrequently}
                >
                  {translations.F_A_Q}
                </Typography>
                <Typography variant="caption" color="white" fontWeight="bold">
                  {translations.BLOGS}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2} xs={4}>
              <Box
                className="flex-container-column cursor-pointer align-items-start"
                gap={pxToRem(16)}
              >
                <Typography variant="caption" color="white" fontWeight="bold">
                  {translations.PRICING}
                </Typography>
                <Typography
                  variant="caption"
                  color="white"
                  fontWeight="bold"
                  onClick={navigateToAboutUs}
                >
                  {translations.ABOUT_US}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2} xs={12}>
              <Box
                className="flex-container-column cursor-pointer  align-items-start"
                gap={pxToRem(16)}
              >
                <Typography variant="caption" color="white" fontWeight="bold">
                  {translations.KEEP_UPDATED}
                </Typography>
                <Box className=" width-100 flex-display">
                  {socialMediaHandlers.map((item) => (
                    <Grid item xs={2} key={item.name}>
                      <Typography component={Link} href={item.link} variant="body1" color="white">
                        {getIcon(item.name)}
                      </Typography>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Grid>
            {/* 
            <Grid item lg={4} xs={12}>
              <Box
                className="justify-content-end flex-display"
                sx={{
                  width: "30%",
                  height: "40%",
                }}
              >
                {selectedLocale && (
                  <SelectInput
                    options={locales.map((item) => ({
                      label: item.language,
                      value: item.language_code,
                    }))}
                    handleChange={updateLocale}
                    selectedData={selectedLocale.language_code}
                  />
                )}
              </Box>
              <Box
                className="flex-container-column"
                sx={{
                  gap: 2,
                  height: "10vh",
                }}
              >
                <Typography variant="caption" color="white" fontWeight="bold" textAlign="left">
                  {translations.SUBSCRIBE_NEWSLETTER}
                </Typography>
                <Input
                  type="email"
                  placeholder={translations.TYPE_EMAIL_HERE}
                  fontWeight="bold"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <ArrowCircleRightRounded
                            sx={() => ({
                              color: "grey.500",
                            })}
                            fontSize="small"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            */}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
