import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { verifyTeamInvite } from "store/actions/auths";
import Dispatcher from "store/Dispatcher";
import { clearAuthErrorState } from "store/slices/authSlice";
import InvitesView from "views/auths/Invites";

function Invites() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [inviteData, setInviteData] = useState(null);
  const { status, errorMessage } = useSelector((state) => state.auths);
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.search) {
      const signature = searchParams.get("signature");
      const decodedString = atob(signature);

      const urlSearchParams = new URLSearchParams(decodedString);
      const inviteDataTemp = Object.fromEntries(urlSearchParams);
      setInviteData({ ...inviteDataTemp, last_name: inviteDataTemp.last_name || "" });
    }
  }, []);

  const acceptTeamInvite = async (requestBody) => {
    const response = await Dispatcher({ action: verifyTeamInvite, payload: requestBody });
    if (response && typeof response !== "string") {
      navigate("/app/surveys");
    }
  };

  const clearError = () => {
    Dispatcher({ action: clearAuthErrorState, isAsync: false });
  };

  const updateInviteData = (value, type) => {
    if (type !== "email") {
      const inviteDataTemp = inviteData;
      inviteDataTemp[type] = value;
      setInviteData(inviteDataTemp);
    }
  };
  return (
    <InvitesView
      inviteData={inviteData}
      acceptTeamInvite={acceptTeamInvite}
      status={status}
      errorMessage={errorMessage}
      clearError={clearError}
      updateInviteData={updateInviteData}
    />
  );
}

export default Invites;
