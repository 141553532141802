import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  InputAdornment,
} from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Button, Input, Typography } from "components";
import { getIcon } from "data/icons/icon";
import { DefaultNavbar, PageLayout } from "modules";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import fuzzySearch from "services/utils/fuzzySearch";
import Footer from "views/auths/components/Footer";
import {
  categoryButton,
  categoryStyle,
  description,
  header,
  subHeader,
} from "views/static/FAQs/style";

const categories = ["Category 1", "Category 2", "Category 3", "Category 4"];

const faqs = [
  {
    id: 1,
    category: "Category 1",
    question: "What does your product do?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 2,
    category: "Category 2",
    question: "How is your product different from competitors?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 3,
    category: "Category 3",
    question: "Is your product suitable for my needs?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 4,
    category: "Category 4",
    question: "What support or resources do you offer for using your product?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 5,
    category: "Category 5",
    question: "Can I try your product before purchasing?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 6,
    category: "Category 6",
    question: "What are the pricing options for your product?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 7,
    category: "Category 7",
    question: "How secure is your product?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 8,
    category: "Category 8",
    question: "What updates or new features can users expect in the future?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    id: 9,
    category: "Category 9",
    question: "How can I provide feedback or suggest improvements for your product?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
];

function FAQs() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { translations } = useSelector((state) => state.generals);

  const navMenuList = [translations.HOW_THIS_WORKS, translations.PRICING, translations.CONTACT].map(
    (nav) => ({
      name: nav,
      route: nav.split("_").join("-").toLowerCase(),
    })
  );

  useEffect(() => {
    setFaqData(fuzzySearch(searchQuery, faqs, "question"));
  }, [faqs, searchQuery]);
  const handleChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) => (prevCategory === category ? null : category));
  };

  return (
    <PageLayout>
      <DefaultNavbar transparent navMenu={navMenuList} />
      <Box
        width="100%"
        height="calc(100vh- 56px)"
        className="flex-display flex-direction-column justify-content-between"
      >
        <Container sx={{ height: "inherit", mt: pxToRem(16) }}>
          <Box className="height-100 flex-display flex-direction-column" gap={pxToRem(16)}>
            <Box className="flex-display flex-direction-column" gap={pxToRem(16)} px={{ md: 15 }}>
              <Typography sx={(theme) => header(theme)}>{translations.F_A_Q}</Typography>
              <Box width={{ xs: "100%", md: "30%" }}>
                <Input
                  value={searchQuery}
                  onChange={handleChangeSearch}
                  placeholder="Search FAQs"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">{getIcon("search")}</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={(theme) => categoryButton(theme)}>
                {categories.map((category, index) => (
                  <Button
                    variant={
                      selectedCategory === `Category ${index + 1}` ? "contained" : "outlined"
                    }
                    onClick={() => handleCategoryClick(`Category ${index + 1}`)}
                    sx={{ p: 0.7, textTransform: "capitalize !important" }}
                  >
                    {category}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box sx={(theme) => categoryStyle(theme)}>
              {faqData.length > 0 ? (
                faqData
                  .filter(
                    (faq) =>
                      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) &&
                      (selectedCategory ? faq.category === selectedCategory : true)
                  )
                  .map((faq) => (
                    <React.Fragment key={faq.id}>
                      <Typography>{faq.category}</Typography>
                      <Accordion square elevation={0} sx={{ "&:before": { display: "none" } }}>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 0 }}>
                          <Typography sx={(theme) => subHeader(theme)}>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <Typography sx={(theme) => description(theme)}>{faq.answer}</Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Divider sx={{ p: 0 }} />
                    </React.Fragment>
                  ))
              ) : (
                <Typography variant="h6">No Search Category Found</Typography>
              )}
            </Box>
          </Box>
        </Container>
        {/* 
        <Box className="width-100" position="absolute" bottom={0}>
          <Footer light />
        </Box>
        */}
      </Box>
    </PageLayout>
  );
}
export default FAQs;
