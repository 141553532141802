function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function convertToLabelValuePairs(array, labelProp, valueProp) {
  return array.map((item) => ({
    label: item[labelProp] ? item[labelProp] : capitalizeFirstLetter(item.code),
    value: item[valueProp],
    code: item.code,
  }));
}
