// prop-types is a library for typechecking of props.
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Divider } from "@mui/material";
// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
//  components
import Box from "components/Box";
//  context
import { useUIController } from "context";
// Custom styles for the SidenavCollapse
import {
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from "modules/Sidenav/styles/sidenavCollapse";
import PropTypes from "prop-types";

function SidenavCollapse({ icon, name, active, open, textColor, collapse, subRoutes, ...rest }) {
  const [controller] = useUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  return (
    <ListItem component="li">
      <Box
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
            miniSidenav,
            subRoutes,
          })
        }
      >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Typography variant="h5" sx={(theme) => collapseIcon(theme, { active })}>
              {icon}
            </Typography>
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              subRoutes,
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
        {collapse ? (
          <KeyboardArrowDown
            color={textColor}
            sx={{
              mr: 1,
              filter: open ? "contrast(100%)" : "contrast(9%)",
              opacity: 1,
              transform: open ? "rotate(-180deg)" : "rotate(0)",
              transition: "0.2s",
            }}
          />
        ) : null}
      </Box>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  icon: null,
  open: false,
  textColor: "white",
  collapse: false,
  subRoutes: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
  collapse: PropTypes.bool,
  subRoutes: PropTypes.bool,
};

export default SidenavCollapse;
