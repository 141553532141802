import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Button, Typography } from "components";
import { Modal } from "modules";
import infoText from "modules/Dialogs/ConfirmationDialog/style";
import PropTypes from "prop-types";
import React from "react";

function ConfirmationDialog({ open, title, confirmBtnText, cancelBtnText, onSubmit, onClose }) {
  return (
    <Modal open={open} handleClose={onClose} className="border">
      <Box
        width={pxToRem(370)}
        className="flex-display flex-direction-column"
        padding={pxToRem(24)}
      >
        <Box className="flex-display align-items-start flex-direction-column" gap={pxToRem(24)}>
          <Typography sx={(theme) => infoText(theme)}>{title}</Typography>
          <Box className="flex-display align-items-start " gap={pxToRem(16)} width="100%">
            <Button variant="outlined" fullWidth onClick={onClose}>
              {cancelBtnText}
            </Button>
            <Button fullWidth onClick={onSubmit}>
              {confirmBtnText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

ConfirmationDialog.defaultProps = {
  open: false,
  title: "Are you sure?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
