import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "assets/theme";
import Invites from "containers/auths/Invites";
import LoginContainer from "containers/auths/Login";
import VerifyContainer from "containers/auths/Verify";
import FAQs from "containers/static/FAQs";
import PrivacyPolicy from "containers/static/PrivacyPolicy";
import { useUIController } from "context";
import { Configurator, DashboardNavbar, ProfileDetailsModal, Sidenav } from "modules";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes, { RequireAuth } from "routes/routes";
import cookiesManipulator from "services/browserStorage/cookies";
import { ConfirmationServiceProvider } from "services/customHooks/useConfirmation";
import { getUserProfile, logout } from "store/actions/auths";
import { getLocales, getLocaleTranslations, getMetadata } from "store/actions/generals";
import Dispatcher from "store/Dispatcher";
import AboutUs from "views/static/AboutUs";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useUIController();
  const { sidenavColor, darkMode } = controller;

  const { pathname } = useLocation();
  const { selectedLocale } = useSelector((state) => state.generals);

  useEffect(() => {
    const fetchInitials = async () => {
      await Dispatcher({ action: getLocales });
      await Dispatcher({ action: getMetadata });
      const isUserAuthenticated = await cookiesManipulator.getAuth().token;
      if (isUserAuthenticated) await Dispatcher({ action: getUserProfile });
    };
    fetchInitials();
  }, []);

  useEffect(() => {
    const fetchTranslations = async () => {
      await Dispatcher({ action: getLocaleTranslations, payload: selectedLocale });
    };
    if (selectedLocale) fetchTranslations();
  }, [selectedLocale]);

  const handleLogout = async () => {
    await Dispatcher({ action: logout, payload: { active: 0 } });
    await navigate("/auths/login");
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, parentRoute = "") =>
    allRoutes.flatMap((route) => {
      const { route: currentRoute, layout, component, subRoutes } = route;
      const fullRoute = parentRoute + currentRoute;
      const routePath = layout + fullRoute;

      const routeComponent = component && (
        <Route
          exact
          path={routePath}
          element={<RequireAuth>{component}</RequireAuth>}
          key={route.key}
        />
      );

      if (subRoutes && subRoutes.length > 0) {
        const subRoutesComponents = getRoutes(subRoutes, fullRoute);

        return [routeComponent, ...subRoutesComponents];
      }

      if (route.fragmentIdentifier) {
        const fragmentPath = `${routePath}#${route.fragmentIdentifier}`;
        const fragmentComponent = component && (
          <Route
            exact
            path={fragmentPath}
            element={<RequireAuth>{component}</RequireAuth>}
            key={`${route.key}-fragment`}
          />
        );

        return [routeComponent, fragmentComponent];
      }

      return routeComponent;
    });

  const hideSidenavOnTheseRoutes = ["templates", "preview", "builder", "auths"];

  function shouldHideSidebar(currentRoute) {
    return !hideSidenavOnTheseRoutes.some((route) => currentRoute.includes(route));
  }

  return (
    <ThemeProvider theme={darkMode ? theme : theme}>
      <CssBaseline />
      <ConfirmationServiceProvider>
        {shouldHideSidebar(location.pathname) && cookiesManipulator.getAuth().token && (
          <>
            <DashboardNavbar handleLogout={handleLogout} />
            <Sidenav
              color={sidenavColor}
              brand="https://dashboard.surveys.xane.ai/static/media/finalLogoXaneAi.42cdabfc.png"
              routes={routes}
            />

            <ProfileDetailsModal />
          </>
        )}

        <Routes>
          {getRoutes(routes)}
          <Route
            path="/auths/login"
            element={
              cookiesManipulator.getAuth().token ? (
                <Navigate to="/app/surveys" />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            path="/auths/verify"
            element={
              cookiesManipulator.getAuth().token ? (
                <Navigate to="/app/surveys" />
              ) : (
                <VerifyContainer />
              )
            }
          />
          <Route
            path="/invites"
            element={
              cookiesManipulator.getAuth().token ? <Navigate to="/app/surveys" /> : <Invites />
            }
          />
          <Route path="*" element={<Navigate to="/auths/login" />} />
          <Route path="/static/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/static/faq" element={<FAQs />} />
          <Route path="/static/about-us" element={<AboutUs />} />
        </Routes>
      </ConfirmationServiceProvider>
    </ThemeProvider>
  );
}
