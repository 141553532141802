/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import { STATUSES } from "data/constants/STATUSES";
import cookies from "services/browserStorage/cookies";
import compareData from "services/validations/compareData";

const uri = "auths";

export const getProviders = createAsyncThunk(
  `${uri}/getProviders`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/providers`, "GET");
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const requestAuth = createAsyncThunk(
  `${uri}/requestAuth`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/requests`, "POST", requestBody);
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const requestSocialAuth = createAsyncThunk(
  `${uri}/requestSocialAuth`,
  async (queryParam, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}/requests/social?provider_id=${queryParam.provider_id}&state=${queryParam.state}`,
        "GET"
      );
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const verifyLogin = createAsyncThunk(
  `${uri}/verifyLogin`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/verify`, "POST", requestBody);
      if (response.code === "success") {
        await cookies.setAuth(response.data);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const getAuthSession = createAsyncThunk(
  `${uri}/getAuthSession`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}/session?provider_id=${requestBody.provider_id}&state=${requestBody.state}`,
        "GET"
      );
      if (response.code === "success") {
        await cookies.setAuth(response.data);

        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const logout = createAsyncThunk(
  `${uri}/logout`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/logout`, "PUT", requestBody);

      if (response.code === "success") {
        await cookies.removeAuth();
        await localStorage.removeItem("sessionState");
        await localStorage.removeItem("email");
        return response.message;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  `${uri}/updateUserProfile`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`users/me`, "PUT", requestBody);
      if (response.code === "success") {
        const missingData = compareData(requestBody, response.data.user);

        if (missingData.length > 0) {
          console.error(new Error(`Server response Mismatch: ${JSON.stringify(missingData)}`));
          return rejectWithValue(`Server response Mismatch`);
        }
        await cookies.setCookies("user", response.data.user);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const getUserProfile = createAsyncThunk(
  `${uri}/getUserProfile`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`users/me`, "GET");
      if (response.code === "success") {
        await cookies.setCookies("user", response.data.user);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const createTeam = createAsyncThunk(
  `${uri}/createTeam`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams`, "POST", requestBody);
      if (response.code === "success") {
        return response.team;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const getTeams = createAsyncThunk(
  `${uri}/getTeams`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams`, "GET");
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const inviteTeamUser = createAsyncThunk(
  `${uri}/inviteTeamUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams/invites`, "POST", requestBody);
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const deleteTeamUser = createAsyncThunk(
  `${uri}/deleteTeamUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams/users/${requestBody.id}`, "PUT", { active: 0 });
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const deleteTeamInvite = createAsyncThunk(
  `${uri}/deleteTeamInvite`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams/invites/${requestBody.id}`, "PUT", { active: 0 });
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const verifyTeamInvite = createAsyncThunk(
  `${uri}/verifyTeamInvite`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`teams/invites/verify`, "POST", requestBody);
      if (response.code === "success") {
        await cookies.setAuth(response.data);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
