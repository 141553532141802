import Fuse from "fuse.js";

export default function fuzzySearch(query, data, key) {
  if (query === "") {
    return data;
  }
  const options = {
    keys: [key],
  };
  const fuse = new Fuse(data, options);
  return fuse.search(query).map((item) => item.item);
}
