import ConfirmationDialog from "modules/Dialogs/ConfirmationDialog";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useRef, useState } from "react";

const ConfirmationServiceContext = createContext(() => Promise.reject());

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export function ConfirmationServiceProvider({ children }) {
  const [confirmationState, setConfirmationState] = useState(null);
  const [open, setOpen] = useState(false);

  const awaitingPromiseRef = useRef({
    resolve: () => {},
    reject: () => {},
  });

  const openConfirmation = useCallback((options) => {
    setConfirmationState(options);
    setOpen(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(e);
    }

    setOpen(false);
  };

  const handleClosed = () => {
    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmationDialog
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onClosed={handleClosed}
        {...confirmationState}
      />
    </>
  );
}

ConfirmationServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
