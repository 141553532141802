import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";

export default function SelectInput({
  options,
  handleChange,
  disabled,
  selectedData,
  placeholder,
  error,
  helperText,
}) {
  const handleSelectChange = (event) => {
    handleChange(event.target.value);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "100px",
      },
    },
  };

  return (
    <FormControl sx={{ height: "100%", width: "100%" }} error={error}>
      <Select
        error={error}
        id="demo-controlled-open-select"
        displayEmpty
        value={
          selectedData.value
            ? options[options.findIndex((option) => option.value === selectedData.value)].value
            : ""
        }
        disabled={disabled}
        onChange={handleSelectChange}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <MenuItem disabled value="" sx={{ padding: 0, height: "inherit" }}>
                <em>{placeholder}</em>
              </MenuItem>
            );
          }

          return (
            <MenuItem sx={{ height: "inherit", padding: 0 }} disableRipple disableGutters>
              {options[options.findIndex((option) => option.value === selected)].label}
            </MenuItem>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value={placeholder}>
          <em>{placeholder}</em>
        </MenuItem>
        {options &&
          options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ whiteSpace: "normal", wordWrap: "break-word", height: "inherit" }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

SelectInput.defaultProps = {
  disabled: false,
  selectedData: "",
  placeholder: "Select...",
  error: false,
  options: [],
  helperText: "",
};

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedData: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
    PropTypes.oneOf([""]),
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
