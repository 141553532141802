import pxToRem from "assets/theme/functions/pxToRem";
import { Alert, Box, Button, Input, Loader, Typography } from "components";
import { STATUSES } from "data/constants/STATUSES";
import { Modal } from "modules";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cookiesManipulator from "services/browserStorage/cookies";
import { updateUserProfile } from "store/actions/auths";
import Dispatcher from "store/Dispatcher";
import { clearAuthErrorState } from "store/slices/authSlice";

function ProfileDetailsModal() {
  const [profileDetails, setProfileDetails] = useState({ name: "", organizationName: "" });
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const { status, errorMessage } = useSelector((state) => state.auths);

  useEffect(() => {
    const { user } = cookiesManipulator.getAuth();
    if (!user?.first_name) {
      setOpen(true);
    }
  }, []);

  const clearError = () => {
    Dispatcher({ action: clearAuthErrorState, isAsync: false });
  };

  const toggleModal = () => {
    setOpen((prevProp) => !prevProp);
  };

  const handleInputChange = (name, selectedValue) => {
    setShowError(false);
    setProfileDetails((prevData) => ({ ...prevData, [name]: selectedValue }));
  };

  const handleNext = async () => {
    const validateData = {
      name: profileDetails.name.trim() === "",
    };
    setShowError(validateData);

    if (profileDetails.name) {
      const payload = {
        first_name: profileDetails.name.split(" ")[0],
        last_name: profileDetails.name.split(" ")[1] ? profileDetails.name.split(" ")[1] : "",
        // organization: profileDetails.organizationName,
      };

      const res = await Dispatcher({ action: updateUserProfile, payload });
      if (res?.user && res?.user.first_name) toggleModal();
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        hideCloseButton
        handleClose={toggleModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Alert
          alertType="error"
          toggle={status === STATUSES.ERROR}
          handleCloseError={clearError}
          duration={5000}
        >
          {errorMessage}
        </Alert>
        <Box
          className="flex-display flex-direction-column"
          gap={pxToRem(24)}
          width={pxToRem(320)}
          padding={pxToRem(10)}
        >
          <Typography variant="h4">What should we call you?</Typography>
          <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
            <Typography variant="h6">Full Name*</Typography>
            <Input
              type="text"
              size="small"
              placeholder="Type your name"
              value={profileDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!(showError && profileDetails.name === "")}
            />
          </Box>
          <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
            <Typography variant="h6">Organisation Name</Typography>
            <Input
              size="small"
              type="text"
              placeholder="Enter organisation name"
              value={profileDetails.organizationName}
              onChange={(e) => handleInputChange("organizationName", e.target.value)}
            />
          </Box>
          <Box>
            <Loader toggle={status === STATUSES.LOADING} />
            <Button fullWidth onClick={handleNext}>
              Next
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
export default ProfileDetailsModal;
