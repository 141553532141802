// prop-types is a library for typechecking of props.
// @mui material components
import Menu from "@mui/material/Menu";
//  components
import Box from "components/Box";
// modules
import DefaultNavbarLink from "modules/Navbars/DefaultNavbar/DefaultNavbarLink";
import PropTypes from "prop-types";

const icons = ["local_library", "attach_money", "call"];
function DefaultNavbarMobile({ open, close, navMenu, light }) {
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <Box px={0.5}>
        {navMenu.map((nav, i) => (
          <DefaultNavbarLink
            icon={icons[i]}
            name={nav.name}
            route={nav.route}
            light={false}
            key={nav.name}
          />
        ))}
        {/* <DefaultNavbarLink icon="donut_large" name="dashboard" route="/dashboard" />
        <DefaultNavbarLink icon="person" name="profile" route="/profile" />
        <DefaultNavbarLink icon="account_circle" name="sign up" route="/authentication/sign-up" />
        <DefaultNavbarLink icon="key" name="sign in" route="/authentication/sign-in" /> */}
      </Box>
    </Menu>
  );
}

DefaultNavbarMobile.defaultProps = {
  light: false,
  navMenu: [],
};

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  light: PropTypes.bool,
  navMenu: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
