const header = ({ typography }) => {
  const { fontWeightRegular, size } = typography;
  return {
    fontSize: size.xxl,
    fontWeight: fontWeightRegular,
  };
};
const subHeader = ({ typography }) => {
  const { fontWeightRegularLight, size } = typography;
  return {
    fontSize: size.sm,
    fontWeight: fontWeightRegularLight,
  };
};
const description = ({ typography }) => {
  const { fontWeightRegularLight, size } = typography;
  return {
    fontSize: size.sm,
    fontWeight: fontWeightRegularLight,
  };
};
const categoryStyle = ({ functions }) => {
  const { pxToRem } = functions;
  return {
    height: pxToRem(420),
    px: { md: 15 },
    overflowY: "scroll",
  };
};
const categoryButton = ({ functions }) => {
  const { pxToRem } = functions;
  return {
    display: "flex",
    flexWrap: "wrap",
    gap: pxToRem(16),
    overflowY: "scroll",
    justifyContent: { xs: "center", md: "start" },
  };
};
export { categoryButton, categoryStyle, description, header, subHeader };
