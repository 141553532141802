/** 
  All of the routes for the kai App are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `component` key is used to store the component of its route.
  11 The 'subRoute' key is used to add the fragment routes.
*/

//  layouts
import Icon from "@mui/material/Icon";
import { Loader } from "components";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { getIcon } from "data/icons/icon";
import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import cookiesManipulator from "services/browserStorage/cookies";

const Surveys = lazy(() => import("containers/app/Surveys"));
const Analytics = lazy(() => import("containers/app/Analytics"));
const Templates = lazy(() => import("containers/app/Surveys/Templates"));
const Preview = lazy(() => import("containers/app/Surveys/Preview"));
const Builder = lazy(() => import("containers/app/Surveys/Builder"));
const EmailPreview = lazy(() => import("containers/app/Surveys/EmailPreview"));
const Respondents = lazy(() => import("containers/app/Respondents"));
const Users = lazy(() => import("containers/app/Respondents/Users"));
const Settings = lazy(() => import("containers/app/Settings"));

function LazySurveys(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Surveys {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

function LazyAnalytics(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Analytics {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyTemplates(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Templates {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyPreview(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Preview {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyBuilder(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Builder {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyRespondents(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Respondents {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyUsers(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Users {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazySettings(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Settings {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyEmailPreview(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <EmailPreview {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

function generateKey(layout, route, fragmentIdentifier) {
  return `/${layout}/${route}${fragmentIdentifier ? `#${fragmentIdentifier}` : ""}`;
}

const routes = [
  {
    type: "collapse",
    name: "Surveys",
    key: generateKey("app", "surveys"),
    icon: getIcon("surveys"),
    route: "/surveys",
    layout: "app",
    component: <LazySurveys />,
    subRoutes: [
      {
        name: "All",
        key: generateKey("app", "surveys", "all"),
        fragmentIdentifier: "all",
      },
      {
        name: "Drafts",
        key: generateKey("app", "surveys", "draft"),
        fragmentIdentifier: "draft",
      },
      {
        name: "In Progress",
        key: generateKey("app", "surveys", "in-progress"),
        fragmentIdentifier: "in-progress",
      },
      {
        name: "Upcoming",
        key: generateKey("app", "surveys", "upcoming"),
        fragmentIdentifier: "upcoming",
      },
      {
        name: "Completed",
        key: generateKey("app", "surveys", "completed"),
        fragmentIdentifier: "completed",
      },
      {
        name: "Deleted",
        key: generateKey("app", "surveys", "deleted"),
        fragmentIdentifier: "deleted",
      },
    ],
  },

  {
    type: "collapse",
    icon: getIcon("respondents"),
    name: "Respondents",
    key: generateKey("app", "respondents"),
    route: "/respondents",
    layout: "app",
    component: <LazyRespondents />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "usersById",
    key: generateKey("app/respondents", "usersById"),
    route: "/respondents/:audienceID/users",
    layout: "app",
    component: <LazyUsers />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "users",
    key: generateKey("app/respondents", "users"),
    route: "/respondents/users",
    layout: "app",
    component: <LazyUsers />,
  },
  {
    type: "collapse",
    name: "Analytics",
    key: generateKey("app", "analytics"),
    icon: getIcon("analytics"),
    route: "/analytics",
    layout: "app",
    component: <LazyAnalytics />,
  },
  {
    type: "collapse",
    name: "Resources",
    key: generateKey("app", "resources"),
    icon: getIcon("resources"),
    route: "/resources",
    layout: "app",
    component: <LazyAnalytics />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Templates",
    key: generateKey("app/surveys", "templates"),
    route: "/surveys/templates",
    layout: "app",
    component: <LazyTemplates />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Preview",
    key: generateKey("app/surveys", "preview"),
    route: "/surveys/:surveyId/preview",
    layout: "app",
    component: <LazyPreview />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys", "builder"),
    route: "/surveys/builder",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys", "builder"),
    route: "/surveys/:surveyId/:action/builder",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "settings"),
    route: "/surveys/:surveyId/:action/builder/settings",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "share"),
    route: "/surveys/:surveyId/:action/builder/share",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "reminders"),
    route: "/surveys/:surveyId/:action/builder/reminders",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "appearance"),
    route: "/surveys/:surveyId/:action/builder/appearance",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "preview"),
    route: "/surveys/:surveyId/:action/builder/preview",
    layout: "app",
    component: <LazyBuilder />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Setting",
    key: generateKey("app/settings", "settings"),
    route: "/settings",
    layout: "app",
    component: <LazySettings />,
  },
  {
    type: "collapse",
    HiddenOnSidebar: true,
    name: "Builder",
    key: generateKey("app/surveys/builder", "share"),
    route: "/surveys/:surveyId/builder/:type/:templateId/preview",
    layout: "app",
    component: <LazyEmailPreview />,
  },
];

export function RequireAuth({ children }) {
  const authed = !!cookiesManipulator.getAuth().token;
  const location = useLocation();
  return authed === true ? (
    children
  ) : (
    <Navigate to="/auths/login" replace state={{ path: location.pathname }} />
  );
}
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default routes;
