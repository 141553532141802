/* eslint-disable camelcase */
import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "data/constants/STATUSES";
import { readFromLocalStorage, writeToLocalStorage } from "services/browserStorage/localStorage";
import {
  deleteOptions,
  deleteQuestions,
  deleteReactions,
  getLaunchTemplates,
  getOptions,
  getQuestions,
  getReactions,
  getReminderTemplates,
  getTemplatePreview,
  getThemes,
  postOption,
  postQuestion,
  postReaction,
  putOption,
  putReaction,
  updateQuestion,
} from "store/actions/collections";

function setLoadingState(state, action) {
  if (state.status === STATUSES.IDLE) {
    state.status = STATUSES.LOADING;
    state.currentRequestId = action.meta.requestId;
  }
}

function setErrorState(state, action) {
  const { requestId } = action.meta;
  if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
    state.errorMessage = action.payload ? action.payload : action.error.message;
    state.currentRequestId = undefined;
    state.status = STATUSES.ERROR;
  }
}

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  questions: [],
  options: [],
  reactions: [],
  reactionsCount: readFromLocalStorage("reactionsCount") || 1,
  followUpReactionsCount: readFromLocalStorage("followUpReactionsCount") || 1,
  questionsCount: readFromLocalStorage("questionsCount") || 1,
  followUpQuestionsCount: readFromLocalStorage("followUpQuestionsCount") || 1,
  optionsCount: readFromLocalStorage("optionsCount") || 1,
  followUpOptionsCount: readFromLocalStorage("followUpOptionsCount") || 1,
  currentBankSuggestion: "questions",
  groupEnabled: true,
  launchTemplates: [],
  reminderTemplates: [],
  template_preview: "",
  themes: [],
};

export const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    clearCollectionErrorState(state) {
      state.status = STATUSES.IDLE;
      state.errorMessage = null;
    },
    setCurrentBankSuggestion(state, action) {
      if (typeof action.payload === "string") {
        state.currentBankSuggestion = action.payload;
      } else {
        state.currentBankSuggestion = action.payload.type;
        writeToLocalStorage(`${action.payload.type}Count`, action.payload.count);
        state[`${action.payload.type}Count`] = action.payload.count;
      }
    },
    setRecentlyUsedSuggestion(state, action) {
      state[action.payload.type] = current(state[action.payload.type]).map((obj) =>
        obj.id === action.payload.item[0].id
          ? { ...obj, recentlyUsedDate: new Date().getTime() }
          : obj
      );
    },
    setBankSuggestionsLength(state, action) {
      writeToLocalStorage(`${action.payload.type}Count`, action.payload.count);
      state[`${action.payload.type}Count`] = action.payload.count;
    },
    setGroupEnabled(state, action) {
      state.groupEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.pending, setLoadingState)
      .addCase(getQuestions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { questions } = action.payload;
          state.status = STATUSES.IDLE;
          state.questions = questions;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getQuestions.rejected, setErrorState)

      .addCase(updateQuestion.pending, setLoadingState)
      .addCase(updateQuestion.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // const { questions } = action.payload;
          // let index;
          // questions.forEach((question) => {
          //   index = state.questions.findIndex((q) => q.id === question.id);
          //   if (index !== -1) {
          //     state.questions[index] = question;
          //   }
          // });

          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateQuestion.rejected, setErrorState)

      .addCase(postQuestion.pending, setLoadingState)
      .addCase(postQuestion.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { questions } = action.payload;
          state.questions = [...questions, ...current(state.questions)];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postQuestion.rejected, setErrorState)

      .addCase(deleteQuestions.pending, setLoadingState)
      .addCase(deleteQuestions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { questions } = action.payload;
          const deletedIds = questions.map((question) => question.id);
          state.questions = [...state.questions.filter((item) => !deletedIds.includes(item.id))];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteQuestions.rejected, setErrorState)

      .addCase(getOptions.pending, setLoadingState)
      .addCase(getOptions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { options } = action.payload;

          state.options = options;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getOptions.rejected, setErrorState)

      .addCase(putOption.pending, setLoadingState)
      .addCase(putOption.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(putOption.rejected, setErrorState)

      .addCase(postOption.pending, setLoadingState)
      .addCase(postOption.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { options } = action.payload;
          state.options = [...options, ...current(state.options)];

          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postOption.rejected, setErrorState)

      .addCase(deleteOptions.pending, setLoadingState)
      .addCase(deleteOptions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { options } = action.payload;
          const deletedIds = options.map((option) => option.id);
          state.options = [...state.options.filter((item) => !deletedIds.includes(item.id))];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteOptions.rejected, setErrorState)

      .addCase(getReactions.pending, setLoadingState)
      .addCase(getReactions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { reactions } = action.payload;
          state.reactions = reactions;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getReactions.rejected, setErrorState)

      .addCase(putReaction.pending, setLoadingState)
      .addCase(putReaction.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // const { reactions } = action.payload;
          // let index;
          // reactions.forEach((reaction) => {
          //   index = state.reactions.findIndex((r) => r.id === reaction.id);
          //   if (index !== -1) {
          //     state.reactions[index] = reaction;
          //   }
          // });

          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(putReaction.rejected, setErrorState)

      .addCase(postReaction.pending, setLoadingState)
      .addCase(postReaction.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { reactions } = action.payload;
          state.reactions = [...reactions, ...current(state.reactions)];

          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postReaction.rejected, setErrorState)

      .addCase(deleteReactions.pending, setLoadingState)
      .addCase(deleteReactions.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { reactions } = action.payload;
          const deletedIds = reactions.map((reaction) => reaction.id);
          state.reactions = [...state.reactions.filter((item) => !deletedIds.includes(item.id))];
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteReactions.rejected, setErrorState)

      .addCase(getLaunchTemplates.pending, setLoadingState)
      .addCase(getLaunchTemplates.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { templates } = action.payload;
          state.launchTemplates = templates;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getLaunchTemplates.rejected, setErrorState)
      .addCase(getReminderTemplates.pending, setLoadingState)
      .addCase(getReminderTemplates.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { templates } = action.payload;
          state.reminderTemplates = templates;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getReminderTemplates.rejected, setErrorState)

      .addCase(getTemplatePreview.pending, setLoadingState)
      .addCase(getTemplatePreview.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { template_preview } = action.payload;
          state.templatePreview = template_preview;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getTemplatePreview.rejected, setErrorState)

      .addCase(getThemes.pending, setLoadingState)
      .addCase(getThemes.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { themes } = action.payload;

          state.themes = themes;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getThemes.rejected, setErrorState);
  },
});

// Action creators are generated for each case reducer function
export const {
  clearCollectionErrorState,
  setCurrentBankSuggestion,
  setBankSuggestionsLength,
  setRecentlyUsedSuggestion,
  setGroupEnabled,
} = collectionSlice.actions;

export default collectionSlice.reducer;
