const convertQuestionsIntoFlatData = (questions) => {
  try {
    const convertedFollowUps = [];
    const convertedQuestions = [];
    questions.forEach((element, index) => {
      const options = element.options.map((option) => {
        const followUp = {
          ...option.question,
          options: option.question.options.map((opt) => ({
            ...opt,
            next_question_id: questions[index + 1]?.uuid || null,
          })),
          sequence: option.question.uuid ? option.question.sequence : 0,
          uuid: option.question.uuid || null,
          reference: option.question.uuid || null,
          category_id: option.question.category_id || 1,
        };
        convertedFollowUps.push(followUp);
        return {
          ...option,
          next_question_id: option.question.sequence
            ? option?.question.uuid
            : questions[index + 1]?.uuid || null,
          question: null,
        };
      });
      const question = {
        ...element.question,
        uuid: element.uuid || null,
        reference: element.uuid || null,
        options,
        sequence: index + 1,
        category_id: element?.question?.category_id || 1,
      };
      convertedQuestions.push(question);
    });

    const finalQuestions = [...convertedQuestions, ...convertedFollowUps];

    const changesForMultipleChoiceQuestions = finalQuestions.map((question) => {
      if (question.answer_type_id === 3) {
        return {
          ...question,
          options: question.options.map((option, index, optionArray) => ({
            ...option,
            reaction: optionArray[0].reaction,
            next_question_id: optionArray[0].next_question_id,
          })),
        };
      }
      return question;
    });

    const filterData = (data) => {
      const seen = new Set();
      return data.filter((item) => {
        if (item.uuid === null) {
          return false; // Exclude items with null uuid
        }
        if (seen.has(item.uuid)) {
          return false; // Exclude items with duplicate uuid
        }
        seen.add(item.uuid);
        return true;
      });
    };

    return filterData(changesForMultipleChoiceQuestions);
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle the error or return a meaningful value
    return []; // Return an empty array as an example
  }
};

export default convertQuestionsIntoFlatData;
