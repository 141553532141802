/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import { STATUSES } from "data/constants/STATUSES";
import compareData from "services/validations/compareData";

const uri = "audiences";

export const getAudiences = createAsyncThunk(
  `${uri}/getAudiences`,
  async (_requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}`, "GET");

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const getAudience = createAsyncThunk(
  `${uri}/getAudience`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/${requestBody.audienceID}`, "GET");

      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const createAudience = createAsyncThunk(
  `${uri}/createAudience`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}`, "POST", requestBody);
      if (response.code === "success") {
        // const missingData = compareData(requestBody, response.data.audience);
        // if (missingData.length > 0) {
        //   console.error(new Error(`Server response Mismatch: ${JSON.stringify(missingData)}`));
        //   return rejectWithValue(`Server response Mismatch`);
        // }
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const updateAudience = createAsyncThunk(
  `${uri}/updateAudience`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      const response = await requestApi(`${uri}/${requestBody.id}`, "PUT", requestBody);
      if (response.code === "success") {
        // const missingData = compareData(requestBody, response.data.audience);
        // if (missingData.length > 0) {
        //   console.error(new Error(`Server response Mismatch: ${JSON.stringify(missingData)}`));
        //   return rejectWithValue(`Server response Mismatch`);
        // }
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const deleteAudience = createAsyncThunk(
  `${uri}/deleteAudience`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/${requestBody.id}`, "PUT", { active: 0 });
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const createUser = createAsyncThunk(
  `${uri}/createUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`users`, "POST", requestBody);
      if (response.code === "success") {
        // const missingData = compareData(requestBody, response.data.user);
        // if (missingData.length > 0) {
        //   console.error(new Error(`Server response Mismatch: ${JSON.stringify(missingData)}`));
        //   return rejectWithValue(`Server response Mismatch`);
        // }
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const updateUser = createAsyncThunk(
  `${uri}/updateUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`users/${requestBody.user_id}`, "PUT", requestBody);
      if (response.code === "success") {
        // const missingData = compareData(requestBody, response.data.user);
        // if (missingData.length > 0) {
        //   console.error(new Error(`Server response Mismatch: ${JSON.stringify(missingData)}`));
        //   return rejectWithValue(`Server response Mismatch`);
        // }
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const createAudienceUser = createAsyncThunk(
  `${uri}/createAudienceUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      const response = await requestApi(`${uri}/users`, "POST", requestBody);
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const getAudienceUser = createAsyncThunk(
  `${uri}/getAudienceUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}/${requestBody.audienceID}/users`,
        "GET",
        requestBody
      );
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const deleteAudienceUser = createAsyncThunk(
  `${uri}/deleteAudienceUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/users/${requestBody.id}`, "PUT", { active: 0 });
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
