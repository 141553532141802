import { API_URL, getIdentity } from "config/apiHandler";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import uniqueId from "services/utils/generateUniqueId";
import { getProviders, requestAuth, verifyLogin } from "store/actions/auths";
import Dispatcher from "store/Dispatcher";
import { clearAuthErrorState, setSelectedProvider, setSessionState } from "store/slices/authSlice";
import LoginView from "views/auths/Login";

function Auth() {
  const [externalPopup, setExternalPopup] = useState(null);
  const { providers, status, errorMessage, selectedProvider } = useSelector((state) => state.auths);
  const navigateTo = useNavigate();
  const currentLocation = useLocation();

  // Fetch providers when the component mounts
  useEffect(() => {
    if (currentLocation.search) return; // Don't fetch providers if there's a search query (magic link)
    Dispatcher({ action: getProviders });
  }, []);

  useEffect(() => {
    if (!externalPopup) {
      return; // Do nothing if there's no externalPopup
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        if (timer) clearInterval(timer); // Stop the timer if the popup is closed
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const AUTH_VERIFICATION_URL = "/auths/verify";
      if (currentUrl.includes(AUTH_VERIFICATION_URL)) {
        externalPopup.close();

        navigateTo(AUTH_VERIFICATION_URL);

        setExternalPopup(null);
        if (timer) clearInterval(timer);
      }
    }, 20);
  }, [externalPopup]);

  const clearError = () => {
    Dispatcher({ action: clearAuthErrorState, isAsync: false });
  };

  const verifyAuthentication = async (requestBody) => {
    clearError();
    const response = await Dispatcher({ action: verifyLogin, payload: requestBody });
    if (response && typeof response !== "string") {
      navigateTo("/app/surveys");
    }
  };

  const requestAuthentication = async (requestBody) => {
    let isRequestSuccessful = false;
    clearError();
    const response = await Dispatcher({ action: requestAuth, payload: { ...requestBody } });
    if (response && typeof response !== "string" && response.name !== "Error") {
      isRequestSuccessful = true;
    }
    return isRequestSuccessful;
  };

  const setSelectedAuthProvider = (provider) => {
    Dispatcher({ action: setSelectedProvider, payload: provider, isAsync: false });
  };

  // Set the dimensions and position of the external authentication popup and open it
  const externalPopupWidth = 500;
  const externalPopupHeight = 600;
  const openSocialAuthPopup = async (provider) => {
    const sessionState = uniqueId(); // Generate a unique session state
    await Dispatcher({ action: setSelectedProvider, payload: provider, isAsync: false });
    await Dispatcher({ action: setSessionState, payload: sessionState, isAsync: false });
    const identifier = await getIdentity();
    const encodedValue = window.btoa(identifier);

    const left = window.screenX + (window.outerWidth - externalPopupWidth) / 2;
    const top = window.screenY + (window.outerHeight - externalPopupHeight) / 2.5;
    const title = `WINDOW TITLE`;
    const url = `${API_URL}/auths/requests/social?state=${sessionState}&provider_id=${provider.id}&identifier=${encodedValue}`;
    const popup = window.open(
      url,
      title,
      `width=${externalPopupWidth},height=${externalPopupHeight},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  return (
    <LoginView
      {...{
        providers,
        status,
        errorMessage,
        selectedProvider,
        setSelectedAuthProvider,
        requestAuthentication,
        verifyAuthentication,
        openSocialAuthPopup,
        clearError,
      }}
    />
  );
}

export default Auth;
