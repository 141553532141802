//  Base Styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import boxShadow from "assets/theme/functions/boxShadow";
//  helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { colored } = boxShadows;
const { inputBorderColor, secondary, grey, transparent } = colors;
const { borderRadius } = borders;
const { size } = typography;
const boxShadowValue = colored.primary
  ? `${boxShadow([0, 0], [10, 0], colors.secondary.main, 0.3)}, ${boxShadow(
      [0, 3],
      [1, -2],
      colors.secondary.main,
      0.2
    )}, ${boxShadow([0, 1], [5, 0], colors.secondary.main, 0.15)}`
  : "none";

const inputOutlined = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      fontSize: size.sm,
      borderRadius: borderRadius.sm,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorderColor,
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: pxToRem(1.5),
          borderColor: secondary.main,
          boxShadow: boxShadowValue,
        },
      },
    },

    notchedOutline: {
      borderColor: inputBorderColor,
    },

    input: {
      color: grey[700],
      padding: pxToRem(12),
      backgroundColor: transparent.main,
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10),
    },

    multiline: {
      color: grey[700],
      padding: 0,
    },
  },
};

export default inputOutlined;
