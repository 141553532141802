export const METADATA = {
  locale_id: 1,
  sequence: 1,
  content_type_id: 1,
  value: "",
};

export const SURVEY_DRAFT = {
  questions: [
    {
      question: {
        is_follow_up: 0,
        answer_type: 1,
        category_id: 1,
        question_metadata: [
          {
            content_type: 1,
            value: "",
            sequence: 0,
          },
        ],
      },
      options: [
        {
          sequence: 0,
          next_question_id: 0,
          option_metadata: [
            {
              content_id: 1,
              value: "",
              sequence: 0,
            },
          ],
          reactions: {
            sequence: 0,
            reaction_metadata: [
              {
                content_id: 1,
                value: "",
                sequence: 0,
              },
            ],
          },

          question: {
            is_follow_up: 1,
            answer_type: 1,
            category_id: 1,
            question_metadata: [
              {
                content_type: 1,
                value: "",
                sequence: 0,
              },
            ],
            options: [
              {
                sequence: 0,
                next_question_id: 0,
                option_metadata: [
                  {
                    content_id: 1,
                    value: "",
                    sequence: 0,
                  },
                ],
                reactions: {
                  sequence: 0,
                  reaction_metadata: [
                    {
                      content_id: 1,
                      value: "",
                      sequence: 0,
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  ],
};

export const QUESTION = {
  follow_up: 0,
  answer_type_id: null,
  category_id: null,
  sequence: 0,
  question_metadata: [METADATA],
};

export const ANSWER = {
  sequence: 0,
  option_metadata: [METADATA],
};

export const REACTION = {
  sequence: 0,
  reaction_metadata: [METADATA],
};

export const ANSWERS = [
  {
    ...ANSWER,
    reaction: REACTION,
    question: { ...QUESTION, follow_up: 1, options: [{ ...ANSWER, reaction: REACTION }] },
  },
];

export const QUESTION_N_OPTIONS = {
  question: QUESTION,
  options: ANSWERS,
  uuid: null,
  sequence: 0,
};

export default SURVEY_DRAFT;
