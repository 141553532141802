import translationEN from "data/locales/en/translation.json";
import translationHI from "data/locales/hi/translation.json";

// the translations
const resources = {
  en: {
    translation: JSON.parse(JSON.stringify(translationEN)),
  },
  hi: {
    translation: JSON.parse(JSON.stringify(translationHI)),
  },
};

Object.freeze(resources);

export default resources;
