import { SURVEY_STATUSES } from "data/constants/STATUSES";

export const tagsTextStyle = (
  { functions: { pxToRem }, palette: { badgeColors }, typography: { fontWeightBold, size } },
  { label }
) => {
  const { draft, success, primary, secondary, warning } = badgeColors;
  const tagsColor = {
    [SURVEY_STATUSES.DRAFT]: draft.text,
    [SURVEY_STATUSES.UPCOMING]: success.text,
    [SURVEY_STATUSES.ONGOING]: primary.text,
    [SURVEY_STATUSES.COMPLETED]: secondary.text,
    question: draft.text,
    option: success.text,
    reaction: primary.text,
    followupquestion: draft.text,
    objective: draft.text,
    subjective: draft.text,
    multiple: draft.text,
    pending: warning.text,
    active: success.text,
  };
  return {
    color: tagsColor[label.toLowerCase().replaceAll(" ", "")],
    fontSize: size.xxs,
    fontWeight: fontWeightBold,
    letterSpacing: pxToRem(0.4),
    textTransform: "capitalize",
  };
};

export const tagsBoxStyle = ({ functions: { pxToRem } }) => ({
  display: "flex",
  gap: pxToRem(4),
  height: pxToRem(16),
  padding: `0px ${pxToRem(8)} 0px 0px`,
  position: "relative",
});

export const boxStyle = ({ functions: { pxToRem }, borders: { borderRadius } }) => ({
  alignItems: "flex-start",
  borderRadius: borderRadius.md,
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "inherit",
});

export const innerBoxStyle = ({ functions: { pxToRem } }) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: pxToRem(10),
  padding: `${pxToRem(8)} 0px`,
  position: "relative",
  width: pxToRem(227),
});
