import { Box, Typography } from "components";
import PropTypes from "prop-types";
import React from "react";
import { tagsTextStyle } from "views/app/Surveys/components/SurveyCard/style";

export default function Tags({ label }) {
  return (
    <Box
      sx={({
        functions: { pxToRem },
        palette: { badgeColors, ...colors },
        borders: { borderRadius },
      }) => {
        const { primary, secondary, draft, success, warning } = badgeColors;

        const tagsColor = {
          draft: draft.background,
          upcoming: success.background,
          ongoing: primary.background,
          completed: secondary.background,
          question: draft.background,
          option: success.background,
          reaction: primary.background,
          followupquestion: draft.background,
          objective: draft.background,
          subjective: draft.background,
          multiple: draft.background,
          pending: warning.background,
          active: success.background,
        };
        return {
          height: "inherit",
          width: "inherit",
          alignItems: "center",
          backgroundColor: tagsColor[label.replaceAll(" ", "").toLowerCase()],
          borderRadius: borderRadius.sm,
          display: "flex",
          justifyContent: "center",
          padding: `${pxToRem(2)} ${pxToRem(8)}`,
        };
      }}
    >
      <Typography sx={(theme) => tagsTextStyle(theme, { label })}>{label}</Typography>
    </Box>
  );
}
Tags.defaultProps = {
  label: "No data",
};
Tags.propTypes = {
  label: PropTypes.string,
};
