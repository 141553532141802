import { Box, Button, Loader, Typography } from "components";
import { STATUSES } from "data/constants/STATUSES";
import { PageLayout } from "modules";
import PropTypes from "prop-types";
import React from "react";

function Verify({ status, errorMessage, onRetryClick }) {
  return (
    <PageLayout>
      <Loader toggle={status === STATUSES.LOADING} />
      {errorMessage ? (
        <Box
          coloredShadow="error"
          borderRadius="md"
          className="width-50 flex-container-column margin-auto"
          sx={{
            display: status === STATUSES.ERROR ? "none" : "flex",
            padding: 1,
            marginTop: "24%",
          }}
        >
          <Typography variant="h3" color="error" textAlign="center">
            {errorMessage}
          </Typography>

          <Button variant="text" color="info" onClick={onRetryClick}>
            try again
          </Button>
        </Box>
      ) : null}
    </PageLayout>
  );
}

Verify.defaultProps = {
  status: STATUSES.IDLE,
  errorMessage: null,
};

Verify.propTypes = {
  status: PropTypes.oneOf([STATUSES.LOADING, STATUSES.ERROR, STATUSES.IDLE]),
  errorMessage: PropTypes.string,
  onRetryClick: PropTypes.func.isRequired,
};
export default Verify;
