const MENUS = Object.freeze({
  profile: [
    { value: 1, label: "Settings", code: "settings" },
    { value: 2, label: "Sign Out", code: "logout" },
  ],
  draft: [
    // { value: 1, label: "Preview", code: "preview" },
    { value: 2, label: "Edit", code: "edit" },
    { value: 3, label: "Duplicate", code: "duplicate" },
    { value: 4, label: "Delete", code: "delete" },
    { value: 4, label: "Demo", code: "demo" },
  ],
  ongoing: [
    { value: 1, label: "Preview", code: "preview" },
    { value: 2, label: "Stop survey", code: "stop" },
    { value: 3, label: "Duplicate", code: "duplicate" },
    { value: 4, label: "Demo", code: "demo" },
  ],
  upcoming: [
    { value: 1, label: "Preview", code: "preview" },
    { value: 2, label: "Duplicate", code: "duplicate" },
    { value: 3, label: "Cancel launch", code: "cancel" },
    { value: 4, label: "Demo", code: "demo" },
  ],
  completed: [
    { value: 1, label: "Preview", code: "preview" },
    { value: 2, label: "View Analytics", code: "analytics" },
    { value: 3, label: "Duplicate", code: "duplicate" },
    { value: 4, label: "Delete", code: "delete" },
    { value: 4, label: "Demo", code: "demo" },
  ],
  builderSteps: [
    { value: 1, label: "Survey", code: "survey" },
    { value: 2, label: "Settings", code: "settings" },
    { value: 3, label: "Share", code: "share" },
    { value: 4, label: "Reminders", code: "reminders" },
    { value: 5, label: "Appearance", code: "appearance" },
    { value: 6, label: "Preview & Launch", code: "preview" },
  ],
  optionTypes: [
    { value: 1, label: "Objective", code: "objective" },
    { value: 2, label: "Subjective", code: "subjective" },
    { value: 3, label: "Multiple", code: "multiple" },
  ],

  reminderMediums: [
    { value: 1, label: "Email", code: "email" },
    { value: 2, label: "SMS", code: "sms" },
  ],
  frequencies: [
    { value: 1, label: "Every hour", code: "every_hour" },
    { value: 2, label: "Every Day", code: "every_day" },
    { value: 3, label: "Every Month", code: "every_month" },
    { value: 4, label: "Every Year", code: "every_year" },
  ],
  respondentsGroup: [
    { value: 1, label: "Group A", code: "group_a" },
    { value: 2, label: "Group B", code: "group_b" },
  ],
  launchMediums: [
    { value: 1, label: "Email", code: "email" },
    { value: 2, label: "SMS", code: "sms" },
  ],
  answerSettings: [{ value: 1, label: "Clear", code: "clear" }],
  questionnaireOperations: [
    { value: 1, label: "Delete", code: "delete" },
    { value: 1, label: "Duplicate", code: "duplicate" },
  ],
  customOptions: [
    { value: 1, label: "Delete", code: "delete" },
    { value: 1, label: "Edit", code: "edit" },
  ],
  sortBy: [
    { value: 1, label: "Name A to Z", code: "sort_name_asc" },
    { value: 1, label: "Name Z to A", code: "sort_name_dsc" },
    { value: 1, label: "Date: Oldest to latest", code: "date_old_new" },
    { value: 1, label: "Date: latest to oldest", code: "date_new_old" },
  ],
});

export default MENUS;
