//  components
import Box from "components/Box";
//  context
import { setLayout, useUIController } from "context";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect } from "react";
// react-router-dom components
import { useLocation } from "react-router-dom";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <Box
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        width: { md: miniSidenav ? "90%" : "75%", xs: "inherit" },
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(96) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right", "width"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </Box>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
