/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "data/constants/STATUSES";
import resources from "data/resources";
import { readFromLocalStorage, writeToLocalStorage } from "services/browserStorage/localStorage";
import convertToLabelValuePairs from "services/utils/convertToLabelValuePairs";
import { getLocales, getLocaleTranslations, getMetadata } from "store/actions/generals";

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  surveyTypes: null,
  categories: null,
  mediums: [],
  locales: [],
  answerTypes: [],
  activeOptionPath: null,
  selectedLocale: readFromLocalStorage("selectedLocale"),
  translations: resources.en.translation,
};

export const generalSlice = createSlice({
  name: "generals",
  initialState,
  reducers: {
    setSelectedLocale(state, action) {
      writeToLocalStorage("selectedLocale", action.payload);
      state.selectedLocale = action.payload;
    },
    setActiveOptionPath(state, action) {
      state.activeOptionPath = action.payload;
    },
    clearGeneralErrorState(state) {
      state.status = STATUSES.IDLE;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ################# Getting locales ####################
      .addCase(getLocales.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getLocales.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { locales } = action.payload;
          state.status = STATUSES.IDLE;
          state.locales = locales;
          const selectedLocale = locales.filter(
            (locale) =>
              locale.language_code === (navigator.language || navigator.userLanguage).split("-")[0]
          )[0];

          state.selectedLocale = selectedLocale || locales[0];
          writeToLocalStorage("selectedLocale", selectedLocale);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getLocales.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })

      .addCase(getLocaleTranslations.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getLocaleTranslations.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const strings = {
            ...resources.en.translation,
            ...action.payload?.translations.strings,
          };
          state.status = STATUSES.IDLE;
          state.translations = strings;

          state.currentRequestId = undefined;
        }
      })
      .addCase(getLocaleTranslations.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getMetadata.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { metadata } = action.payload;
          state.categories = convertToLabelValuePairs(metadata.categories, "category", "id");
          state.surveyTypes = convertToLabelValuePairs(metadata.interfaces, "interface", "id");
          state.mediums = convertToLabelValuePairs(metadata.mediums, "mediums", "id");
          state.answerTypes = convertToLabelValuePairs(metadata.answer_types, "answer_type", "id");
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getMetadata.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearReference,
  clearAuthErrorState,
  setSelectedLocale,
  setSessionState,
  setActiveOptionPath,
} = generalSlice.actions;

export default generalSlice.reducer;
