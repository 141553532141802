/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import { STATUSES } from "data/constants/STATUSES";
import convertQuestionsIntoFlatData from "services/utils/convertQuestionsIntoFlatData";
import store from "store/store";

const uri = "surveys";

export const getTemplates = createAsyncThunk(
  `${uri}/getTemplates`,
  async (_requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    const { selectedLocale } = getState().generals;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}?locale_id=${selectedLocale.id}&template=1&group=category`,
        "GET"
      );
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const getSurveys = createAsyncThunk(
  `${uri}/getSurveys`,
  async (_requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    const { selectedLocale } = getState().generals;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}?locale_id=${selectedLocale.id}&template=0`, "GET");
      const inActiveResponse = await requestApi(
        `${uri}?locale_id=${selectedLocale.id}&template=0&active=0`,
        "GET"
      );

      if (response.code === "success" && inActiveResponse.code === "success") {
        const surveys = [response.data.surveys, inActiveResponse.data.surveys];
        return { surveys };
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const getDeletedSurveys = createAsyncThunk(
  `${uri}/getDeletedSurveys`,
  async (_requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    const { selectedLocale } = getState().generals;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}?locale_id=${selectedLocale.id}&template=0&active=0`,
        "GET"
      );

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const getSurvey = createAsyncThunk(
  "survey/getSurvey",
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    const { selectedLocale } = getState().generals;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(
        `${uri}/${requestBody.surveyId}?locale_id=${selectedLocale.id}`,
        "GET"
      );
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const createSurvey = createAsyncThunk(
  "survey/createSurvey",
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    let body = { ...requestBody };
    const hasDraftData = "questions" in requestBody;
    if (hasDraftData) {
      const flatQuestions = await convertQuestionsIntoFlatData(requestBody.questions);
      body = { ...body, draft_data: { questions: flatQuestions } };
      delete body.questions;
    }
    try {
      const response = await requestApi(`${uri}`, "POST", body);
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "survey/updateSurvey",
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    let body = { ...requestBody };
    const hasDraftData = "questions" in requestBody;
    if (hasDraftData) {
      const flatQuestions = await convertQuestionsIntoFlatData(requestBody.questions);
      body = { ...body, draft_data: { questions: flatQuestions } };
      delete body.questions;
    }
    try {
      const response = await requestApi(`${uri}/${requestBody.id}`, "PUT", body);
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
export const deleteSurvey = createAsyncThunk(
  "survey/deleteSurvey",
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/${requestBody.id}`, "PUT", { active: 0 });
      if (response.code === "success") {
        return response.data;
      }
      return rejectWithValue(response.message);
    } catch (err) {
      throw err;
    }
  }
);
