const header = ({ typography }) => {
  const { fontWeightRegular, size } = typography;
  return {
    fontSize: size.xxl,
    fontWeight: fontWeightRegular,
  };
};
const subHeader = ({ typography }) => {
  const { fontWeightRegular, size } = typography;
  return {
    fontSize: size.lg,
    fontWeight: fontWeightRegular,
  };
};
const showMoreRead = ({ typography }) => {
  const { fontWeightRegularLight, size } = typography;
  return {
    fontSize: size.sm,
    fontWeight: fontWeightRegularLight,
    textDecoration: "underline",
    textTransform: "capitalize",
    cursor: "pointer",
  };
};
const description = ({ typography }) => {
  const { fontWeightRegularLight, size } = typography;
  return {
    fontSize: size.sm,
    fontWeight: fontWeightRegularLight,
  };
};

export { description, header, showMoreRead, subHeader };
