import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import cookiesManipulator from "services/browserStorage/cookies";
import { readFromLocalStorage } from "services/browserStorage/localStorage";
import { getAuthSession, verifyLogin } from "store/actions/auths";
import Dispatcher from "store/Dispatcher";
import { clearAuthErrorState } from "store/slices/authSlice";
import VerifyView from "views/auths/Verify";

function Verify() {
  const { status, errorMessage, selectedProvider, sessionState } = useSelector(
    (state) => state.auths
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (cookiesManipulator.getAuth().token) {
      navigate("/app/surveys");
    }
  }, [cookiesManipulator.getAuth().token]);

  const clearError = () => {
    Dispatcher({ action: clearAuthErrorState, isAsync: false });
  };

  const authenticationVerification = async (requestBody) => {
    const response = Dispatcher({ action: verifyLogin, payload: requestBody });
    if (response && typeof response !== "string") {
      navigate("/app/surveys");
    }
  };
  const authenticationSession = async (requestBody) => {
    const response = await Dispatcher({ action: getAuthSession, payload: requestBody });
    if (response && typeof response !== "string") {
      navigate("/app/surveys");
    }
  };
  const onRetryClick = () => {
    clearError();
    navigate("/auths/login");
  };

  useEffect(() => {
    if (location && location.search) {
      const signature = searchParams.get("signature");
      const decodedString = atob(signature);

      const urlSearchParams = new URLSearchParams(decodedString);

      const email = urlSearchParams.get("email");
      const token = urlSearchParams.get("token");
      const providerId = urlSearchParams.get("provider_id");
      const requestBody = {
        provider_id: providerId,
        email,
        token,
      };
      authenticationVerification(requestBody);
    }
  }, []);

  useEffect(() => {
    if (location && !location.search) {
      const requestBody = {
        provider_id: selectedProvider.id,
        state: sessionState,
      };
      authenticationSession(requestBody);
    }
  }, []);

  return <VerifyView status={status} errorMessage={errorMessage} onRetryClick={onRetryClick} />;
}

export default Verify;
