/* eslint-disable no-restricted-syntax */
function compareData(requestBody, responseData) {
  if (!responseData) {
    return [requestBody];
  }
  const missingData = [];

  for (const [key, value] of Object.entries(requestBody)) {
    if (!(key in responseData) || responseData[key] !== value) {
      missingData.push({ key, value });
    }
  }

  return missingData;
}

export default compareData;
