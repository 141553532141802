/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});

const DefaultParams = {
  ACL: "public-read",
  Body: ``,
  Bucket: process.env.REACT_APP_S3_BUCKET,
  ContentType: `image/jpeg`,
  ContentEncoding: "base64",
  Key: ``,
};

const upload = (params) =>
  s3
    .upload(params)
    .promise()
    .then(async (data) => data)
    .catch((err) => {
      throw err;
    });

const uploadToS3 = async (requestBody) => {
  const params = {
    ...DefaultParams,
    ...requestBody,
  };

  try {
    const response = await upload(params);

    if (response) {
      return response;
    }
  } catch (err) {
    alert(err);

    throw err;
  }
};

export default uploadToS3;
